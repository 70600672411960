import { Injectable, Renderer2, RendererFactory2, OnDestroy } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ThemeService implements OnDestroy {
  private renderer: Renderer2;
  private colorSchemeMediaQuery: MediaQueryList;
  private mediaQueryListener: (e: MediaQueryListEvent) => void;

  constructor(private rendererFactory: RendererFactory2) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
    this.colorSchemeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');

    this.mediaQueryListener = (e: MediaQueryListEvent) => {
      if (this.getCurrentTheme() === 'auto') {
        this.applyTheme(e.matches ? 'dark' : 'light');
      }
    };
    this.colorSchemeMediaQuery.addEventListener('change', this.mediaQueryListener);

    // Sayfa yüklendiğinde temayı uygula
  //  this.initializeTheme();
  }

  applyTheme(theme: 'light' | 'dark' | 'auto') {
    if (theme === 'auto') {
      theme = this.colorSchemeMediaQuery.matches ? 'dark' : 'light';
    }

    localStorage.setItem('theme', theme);
    this.renderer.setAttribute(document.documentElement, 'data-theme', theme);

    document.body.classList.remove('dark-theme', 'light-theme');
    document.body.classList.add(`${theme}-theme`);
  }

  getCurrentTheme(): 'light' | 'dark' | 'auto' {
    return (localStorage.getItem('theme') as 'light' | 'dark' | 'auto') || 'auto';
  }

  initializeTheme() {
    const savedTheme = this.getCurrentTheme();
    this.applyTheme(savedTheme);
  }

  ngOnDestroy() {
    this.colorSchemeMediaQuery.removeEventListener('change', this.mediaQueryListener);
  }
}
