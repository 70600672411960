import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ApiService } from './network/api.service';
@Injectable({
  providedIn: 'root'
})
export class ParkAreasService {

  baseUrl:String=environment.parkAreaApiUrl;

  constructor(
    private _network:ApiService
  ) { }

   get() {
    const result =  this._network.get(this.baseUrl+"/parkarea");
    return result;
  }
   getByCompany(companyId:any,limit:any,skip:any) {
   const result =  this._network.get(this.baseUrl+"/parkareas/"+companyId+"/"+"?limit=" + limit + '&skip=' + skip);
   return result;
 }
   getById(id:any) {
    const result =  this._network.get(this.baseUrl+"/parkarea/"+id);
    return result;
  }
   post(parkArea:any) {
    const result =  this._network.post(this.baseUrl+"/parkarea",parkArea);
    return result;
  }
   put(parkArea:any) {
    const result =  this._network.put(this.baseUrl+"/parkarea/"+parkArea._id,parkArea);
    return result;
  }
   putResponsibles(responsibles:any,userId:any) {
    const result =  this._network.put(this.baseUrl+"/parkarea/"+userId +"/responsibles",{"parkAreaIds":responsibles});
    return result;
  }
   delete(id:any) {
    const result =  this._network.delete(this.baseUrl+"/parkarea/"+id);
    return result;
  }

}
