import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.css'],
    standalone: false
})
export class ToolbarComponent implements OnInit {
  @Input() title:any;
  @Input() subtitle:any;
  @Input() fontSize:any;
  constructor() { }

  ngOnInit() {
  }

}
