import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'list-tile',
    templateUrl: './list-tile.component.html',
    styleUrls: ['./list-tile.component.css'],
    standalone: false
})
export class ListTileComponent implements OnInit {
  @Input() title:any;
  @Input() subtitle:any;
  @Input() trailing:any;
  @Input() icon?:any;


  constructor() { }

  ngOnInit() {
  }

}
