import { Injectable } from '@angular/core';
import { Router, CanActivate, UrlTree } from '@angular/router';
import { AuthService } from './auth.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable } from 'rxjs';
import { TokenService } from '../token.service';
@Injectable()
export class AuthGuardService implements CanActivate{

  constructor(public auth: AuthService, public router: Router, public jwtHelper: JwtHelperService, private _token:TokenService) { }
  canActivate(): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
     if (this.jwtHelper.isTokenExpired(this._token.getToken!)) {
      this.router.navigate(['auth/login']);
      return false;
    }
    if(this.auth.getRole=='Admin' || this.auth.getRole=='Supervisor'){
      return true
    }else{
      this.router.navigate(['auth/login']);
      return false
    }
  }
}


