import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiKeysService {

  baseUrl:String=environment.personApiUrl;

  constructor(
    private _api:ApiService
  ) { }

  get() {
    const result =  this._api.get(this.baseUrl+"/apikey");
    return result;
  }
   getList(id:any,limit:any,skip:any) {
    let params=new HttpParams()
    .set("limit",limit)
    .set("skip",skip)
    const result =  this._api.get(this.baseUrl+"/apikeys?limit="+limit+"&skip="+skip+"&companyId="+id);
    return result;
  }
   getById(id:any) {
    const result =  this._api.get(this.baseUrl+"/apikey/"+id);
    return result;
  }
   post(model:any) {
    const result =  this._api.post(this.baseUrl+"/apikey",model);
    return result;
  }
   put(model:any,id:any) {
    const result =  this._api.put(this.baseUrl+"/apikey/"+id,model);
    return result;
  }
   delete(id:any) {
    const result =  this._api.delete(this.baseUrl+"/apikey/"+id);
    return result;
  }

}
