import { NgModule } from '@angular/core';
import { ListTileComponent } from './list-tile/list-tile.component';
import { CommonModule } from '@angular/common';
import { ToolbarComponent } from './toolbar/toolbar.component';
import { BannerComponent } from './banner/banner.component';
import { AvatarComponent } from './avatar/avatar.component';
import { PricingCardComponent } from './pricing-card/pricing-card.component';


@NgModule({
    declarations: [
        ListTileComponent,
        ToolbarComponent,
        BannerComponent,
        AvatarComponent,
        PricingCardComponent,
    ],
    imports     : [
        CommonModule
    ],
    exports     : [   
        ListTileComponent,
        ToolbarComponent,
        BannerComponent,
        AvatarComponent,
        PricingCardComponent,
     ]
})
export class ComponentsModule 
{
}
