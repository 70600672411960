import { Injectable } from '@angular/core';
import * as L from 'leaflet';
import { LatLngTuple } from 'leaflet';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class MapService {
  map: any;
  baseLayers: any;
  googleStreets: any;
  googleSat: any;
  osm: any;
  currentLayerGroup: any;
  markersGroup: any;
  parkingLotLayerList: any = [];
  currentParkingLotList: any = [];
  currentRatio: String = '';
  imageOverlay: any;
  zoomLevel: number = 10;
  currentParkArea:any;
  constructor() { }
  initMap(): void {
    // Eğer harita zaten başlatılmışsa tekrar başlatma!
    if (this.map) {
      console.warn("Map already initialized!");
      return;
    }
  
    const m = this;
  
    this.osm = L.tileLayer(
      "https://a.tile.openstreetmap.org/{z}/{x}/{y}.png",
      {
        maxZoom: 20,
        subdomains: ["mt0", "mt1", "mt2", "mt3"]
      }
    );
    this.googleStreets = L.tileLayer(
      "http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}",
      {
        maxZoom: 20,
        subdomains: ["mt0", "mt1", "mt2", "mt3"]
      }
    );
    this.googleSat = L.tileLayer(
      "http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}",
      {
        maxZoom: 20,
        subdomains: ["mt0", "mt1", "mt2", "mt3"]
      }
    );
    var zoomControl: boolean = true;
  
    // Harita container'ı temizle
    if (document.getElementById('map')) {
      document.getElementById('map')!.innerHTML = "";
    }
  
    // Haritayı başlat
    this.map = L.map('map', {
      zoom: this.zoomLevel,
      layers: [this.googleSat],
      zoomControl: zoomControl,
      attributionControl: false,
      bounceAtZoomLimits: false
    });
  
    this.startingBounds();
  
    this.baseLayers = {
      Satellite: this.googleSat,
      Osm: this.osm,
      Google: this.googleStreets
    };
  
    this.map.zoomControl.setPosition('bottomright');
  
    L.control
      .layers(this.baseLayers, undefined, { position: "bottomleft" })
      .addTo(this.map);
  
    this.currentLayerGroup = L.layerGroup();
    this.map.addLayer(this.currentLayerGroup);
  
    this.markersGroup = L.featureGroup().addTo(this.map);
  
    this.map.on('zoomend', function (e: any) {
      m.zoomLevel = e.target._zoom;
      m._redrawMarkerByZoomLevel();
    });
  
    setTimeout(() => {
      this._redrawMarkerByZoomLevel()
    }, 2000);
  }
  

  startingBounds() {
    var southWest = L.latLng(47.2, 5.5),
      northEast = L.latLng(55.1, 15.5),
      bounds = L.latLngBounds(southWest, northEast);
    this.map.fitBounds(bounds);
  }

  createHtmlMarker(lot: any) {
    let location = lot.location.split(', ')
    let icon = L.marker([location[0], location[1]], {
      icon: L.divIcon({
        className: ((parseFloat(lot.deviceCountinuingParkingTime)) >= parseFloat(localStorage.getItem("parkingLimit")!) && lot.status != 'available') ? 'fine-icon' : lot.status == 'available' ? (lot.type == 'disabled' || lot.type == 'electric') ? 'custom-icon' : 'available-icon' : 'occupied-icon',
        html: lot.type == 'normal' ? lot.slotNumber : ("<img src='../../assets/marker/" + (lot.type == 'disabled' ? 'accessible.svg' : 'electric_car.svg') + "'" + "/>")
      })
    }).bindPopup(this._lotPopUp(lot)).addTo(this.currentLayerGroup);
    return icon
  }

  createParkMarker(lot: any, ratio: any) {
    let location = lot?.location.split(', ')
    let icon = L.marker([location[0], location[1]], {
      icon: L.divIcon({
        className: parseInt(ratio.split('%')[0]) <= 30 ? 'park-icon-30' : parseInt(ratio.split('%')[0]) <= 60 ? 'park-icon-30' : 'park-icon-90',
        html: ratio
      })
    }).on('click', (e: any) => this._fitBounds()).addTo(this.currentLayerGroup);
    return icon
  }

  zoomFitBounds(lat: any, lng: any) {
    this.map.setView([lat, lng], 19);
  }

  setWithPlan(parkArea: any) {
    if (!parkArea || !parkArea.parkPlanUrl || !parkArea.location) {
      console.error('Geçersiz park alanı verileri');
      return;
    }

    const imageUrl = parkArea.parkPlanUrl;
    const centerLatLng = this._locationParser(parkArea.location);
    const s3BucketUrl = environment.s3Bucket;
    const fullImageUrl = s3BucketUrl + imageUrl;

    const img = new Image();

    img.onload = () => {
      // Görsel boyutlarını al
      const imageWidth = img.width;  // 1920 olmalı
      const imageHeight = img.height; // 1080 olmalı
      const aspectRatio = imageHeight / imageWidth; // 0.5625

      // Binanın gerçek dünya genişliği (metre cinsinden)
      // Bu değeri API'den alabilir veya manuel girebilirsiniz
      const realWorldWidth = parkArea.widthInMeters || 200; // örnek: 200 metre

      // Gerçek dünya yüksekliğini aspect ratio'yu kullanarak hesapla
      const realWorldHeight = realWorldWidth * aspectRatio;

      // Enlem ve boylam farkını hesapla
      const latRadians = centerLatLng[0] * Math.PI / 180;
      const metersPerDegreeLat = 111320; // yaklaşık olarak her enlem için sabit
      const metersPerDegreeLon = 111320 * Math.cos(latRadians); // enleme göre değişir

      // Enlem ve boylam değişimlerini hesapla
      const latDiff = realWorldHeight / metersPerDegreeLat / 2;
      const lonDiff = realWorldWidth / metersPerDegreeLon / 2;

      // Koordinatları hesapla
      const southWest: any = [centerLatLng[0] - latDiff, centerLatLng[1] - lonDiff];
      const northEast: any = [centerLatLng[0] + latDiff, centerLatLng[1] + lonDiff];

      // Önceki overlay varsa kaldır
      if (this.imageOverlay) {
        this.map.removeLayer(this.imageOverlay);
      }

      // Görsel sınırlarını belirle
      const bounds = L.latLngBounds(southWest, northEast);

      this.imageOverlay = L.imageOverlay(fullImageUrl, bounds, {
        opacity: 1,
        errorOverlayUrl: 'https://cdn-icons-png.flaticon.com/512/110/110686.png',
        alt: 'Parklab GmbH',
        interactive: true
      }).addTo(this.map);

      // Görselin haritada net görünmesi için fitBounds
      this.map.fitBounds(bounds, {
        padding: [20, 20],
        maxZoom: 19
      });

      // DEBUG - Konsola bilgileri yazdır
      console.log('Görsel bilgileri:', {
        imageWidth,
        imageHeight,
        aspectRatio,
        realWorldWidth,
        realWorldHeight,
        centerLatLng,
        bounds: bounds.toBBoxString()
      });
    };

    img.onerror = () => {
      console.error('Resim yüklenirken hata oluştu:', fullImageUrl);
    };

    img.src = fullImageUrl;
  }


  private _locationParser(coordinateString: any) {
    const [lat, lng] = coordinateString.split(',').map((coord: any) => parseFloat(coord.trim()));
    return [lat, lng] as LatLngTuple;
  }

  addParkingLotList(parkingLotList: any[], ratio: any, isFit?: boolean) {
    this.currentParkingLotList = parkingLotList;
    this.currentRatio = ratio;
    this.currentLayerGroup.clearLayers();
    if (this.zoomLevel >= 14) {
      this.parkingLotLayerList = [];
      parkingLotList.forEach((lot: any) => {
        this.parkingLotLayerList.push(this.createHtmlMarker(lot));
      });
      if (isFit) {
        this._fitBounds();
      }

    } else {
      this.parkingLotLayerList.push(this.createParkMarker(parkingLotList[0], ratio));
    }

  }

  private _redrawMarkerByZoomLevel() {
    this.currentLayerGroup.clearLayers();
    this.addParkingLotList(this.currentParkingLotList, this.currentRatio);
  }

  private _fitBounds() {
  /*   if (this.imageOverlay) {
      this.map.removeLayer(this.imageOverlay);
    } */
    this.currentLayerGroup.clearLayers();
    this.parkingLotLayerList = [];
    this.currentParkingLotList.forEach((lot: any) => {
      this.parkingLotLayerList.push(this.createHtmlMarker(lot));
    });
    var group = L.featureGroup(this.parkingLotLayerList);
    this.map.fitBounds(group.getBounds());
  }

  private _lotPopUp(lot: any): any {
    return `
  <span style="font-size:16px;padding-bottom:8px"><b>Parknummer ${lot.slotNumber}</b></span><br>
  <span style="font-size:14px;padding-bottom:6px"><b>Zeit</b> ${isNaN(parseFloat(lot.deviceCountinuingParkingTime)) ? '---' : this.deviceCountinuingParkingTime(lot)} </span><br>
  `
  }

  deviceCountinuingParkingTime(parkSlot: any) {
    let parkingTime = parseFloat(parkSlot.deviceCountinuingParkingTime);
    if (parkingTime < 60) {
      return Math.trunc(parkingTime) + ' min'
    }
    if (parkingTime >= 60 && parkingTime < 1440) {
      let remainderMinute = Math.trunc((parkingTime % 60)) + 'min';
      return (~~(parkingTime / 60)) + 'Std ' + remainderMinute;
    }
    let totalHour = (parkingTime / 60);
    let totalDay = Math.trunc((totalHour / 24)) + 'Tag ';
    let remainderHour = Math.trunc((totalHour % 24)) + 'Std '
    return totalDay + remainderHour;
  }

}
