import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'platform'
})
export class TranslateService {
translateData: any = {};
currentLang: any;
langs=[
  {viewValue:'English',value:'en-US'},
  {viewValue:'Türkçe',value:'tr-TR'},
  {viewValue:'Deutsch',value:'de-DE'}
]
constructor(public _http:HttpClient,private _title:Title) {
  if(!this.currentLang){
    this.languageDetection();
  }
}

language(lang: string): Promise<{}> {
  this.currentLang = lang;
  return new Promise<any>((resolve, reject) => {
    const langPath = `assets/i18n/i18n.json`;
    this._http.get<any>(langPath).subscribe(
      (translation: any) => {
        Object.keys(translation).forEach((key) => {
          translation[key] = translation[key][lang];
        });
        this.translateData = Object.assign({}, translation || {});
        resolve(this.translateData);
      },
      error => {
        console.log(error);
        this.translateData = {};
        resolve(this.translateData);
      }
    );
  });
}
languageDetection() {
  let lang:any;
  if(localStorage.getItem("lang")){
      lang=localStorage.getItem("lang");
  }else{
    lang = window.navigator.languages ? window.navigator.languages[0] : null;
    lang = lang || window.navigator.language;
  }
  let shortLang = lang;
  if (shortLang.indexOf('-') !== -1)
    shortLang = shortLang.split('-')[0];

  if (shortLang.indexOf('_') !== -1)
    shortLang = shortLang.split('_')[0];
  shortLang == "de" ? this.language("de-DE") : shortLang == "tr" ? this.language("tr-TR") : this.language("en-US")
}
transform(key: any): any {
  return this.translateData[key];
}
setTitle(title:any){
  this._title.setTitle(environment.appName+(this.transform(title) ? (' - '+this.transform(title)):''));
}

}
