import { NgModule } from '@angular/core';
import { TranslatePipe } from './translate.pipe';
import { AuthImagePipe } from './auth-image.pipe';

@NgModule({
    declarations: [
        TranslatePipe,
        AuthImagePipe
    ],
    imports     : [
    ],
    exports     : [   
        TranslatePipe,
        AuthImagePipe
    ]
})
export class PipesModule 
{
}
