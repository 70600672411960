import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';


@Component({
    selector: 'app-avatar',
    templateUrl: './avatar.component.html',
    styleUrls: ['./avatar.component.css'],
    standalone: false
})
export class AvatarComponent implements OnInit {
  @Input() url: any | undefined;
  @Output() upload = new EventEmitter<string>();
  uploading = false;
  constructor(private sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
  }
  async uploadAvatar(event: any) {
    try {
      this.uploading = true;
      if (!event.target.files || event.target.files.length === 0) {
        throw new Error('You must select an image to upload.');
      }

      const file = event.target.files[0];
      const fileExt = file.name.split('.').pop();
      const fileName = `${Math.random()}.${fileExt}`;
      this.url =this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(event.target.files[0]));
      this.upload.emit(file);
    } catch (error:any) {
      alert(error.message);
    } finally {
      this.uploading = false;
    }
  }
}