import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpCommonInterceptor } from './interceptors/http.common.interceptor';
import {
  ApiService,
  AuthGuardService,
  AuthService,
  UtilityService,
  TokenService,
  ThemeService,
  TranslateService,
  GraphqlService,
  MapService,
  ParkAreasService,
  ParkSlotsService,
  ApiKeysService
  
} from './services';
import { ComponentsModule } from './components/components.module';
import { PipesModule } from './pipes/pipes';
import { GraphQLModule } from './services/network/graphql.module';
import { Apollo } from 'apollo-angular';



@NgModule({
  imports: [
    CommonModule,
    ComponentsModule,
    PipesModule,
    GraphQLModule
  ],
  providers: [
    Apollo,
    { provide: HTTP_INTERCEPTORS, useClass: HttpCommonInterceptor, multi: true },
    ApiService,
    AuthGuardService,
    AuthService,
    TokenService,
    UtilityService,
    ThemeService,
    GraphqlService
  ],
  exports: [
    PipesModule,
    ComponentsModule,
    GraphQLModule
  ],
  declarations: [
  ]
})
export class CoreModule { }